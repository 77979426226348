.paquete_card{
    background-color: white;
    border-radius: 5px;
    padding: 4% 2%;
    box-shadow: 0px 3px 8px 2px #44adab93;
    height: 98%;
}

.paquete_horas{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #5c5b5b;
}
.paquete_precio{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 35px;
    color: #555555;
}
.title_beneficios{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #555555;
}
.title_1, .title_2, .title_3{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 18px;
}
.title_1{
    color: #92ad47;
}
.title_2{
    color: #369b99;
}
.title_3{
    color: #0766AD;
}
.btn_1, .btn_2, .btn_3{
    color: white; 
    border: none;
    border-radius: 15px;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    cursor: pointer;
    width: 80%;
    height: 35px;
    font-size: 15px;
}
.btn_1:hover, .btn_2:hover, .btn_3:hover{
    color: white !important;
    scale: 1.1;
}
.btn_1{
    background-color: #829b3d;
}
.btn_2{
    background-color: #369b99;
}
.btn_3{
    background-color: #0766AD;
}
.tag_1, .tag_2, .tag_3{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 12px !important;
    background-color: white !important;
    border-radius: 12px;
    padding: 2px 10px;
    margin-top: 8px;
}
.tag_1{
    color: #829b3d;
    border: 2px solid #829b3d;
}
.tag_2{
    color: #369b99;
    border: 2px solid #369b99;
}
.tag_3{
    color: #0766AD;
    border: 2px solid #0766AD;
}

.btn_counter_1{
    border-color: #829b3d;
    color: #92ad47;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 0;
    height: 25px !important;
}
.btn_counter_1:hover{
    scale: 1.1;
    border-color: #829b3d !important;
    color: #92ad47 !important;
}
.btn_counter_2{
    border-color: #369b99;
    color: #369b99;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 0;
    height: 25px !important;
}
.btn_counter_2:hover{
    border-color: #369b99 !important;
    color: #369b99 !important;
    scale: 1.1;
}
.btn_counter_3{
    border-color: #0766AD;
    color: #0766AD;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 0;
    height: 25px !important;
}
.btn_counter_3:hover{
    border-color: #0766AD !important;
    color: #0766AD !important;
    scale: 1.1;
}

.title_counter{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
}