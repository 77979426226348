.form_card{
    background-color: white;
    border-radius: 5px;
    padding: 1% 1%;
    box-shadow: 0px 3px 8px 2px #44adab93;
    height: 100%;
}
.col_input{
    padding: 0 10px;
}

.col_toolTip{
    display: flex;
    align-items: center;
    color: #44adab;
}
.col_termcond{
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.col_title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
}
.title_form{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 15px;
    color: #44adab;
}

.ant-select-multiple .ant-select-selection-placeholder{
    inset-inline-end: 0 !important;
    text-align: start;
}

.btn_pay{
    border-radius: 8px;
    background: linear-gradient(180deg, #44ADAB 28.65%, #4DA6DE 100%);
    width: 100%;
    height: 35px;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
}

.img_card{
    width: 60% !important;
}