.titleEncuesta{
    color: #3ba3a1;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    margin-bottom: 5px;
    font-weight: 700;
    margin-top: -10px;
}
.descriptionEncuesta{
    color: #424141;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 700;
    margin-top: -10px;
    text-align: justify;
}