.cardForgetPass{
    background-color: white;
    border-radius: 12px;
    padding: 20px 0px;
    box-shadow: 2px 6px 8px 2px rgba(0,0,0,0.33);
}
.titleForgetPss{
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #44ADAB;
}
.formForgetOne{
    width: 100%;
    margin-top: 30px;
    padding: 0% 8%;
}
.inputForget{
    font-family: 'Lato', sans-serif;
    width: 100%;
    color: rgb(129, 129, 129);
    border: 1px solid rgb(163, 163, 163);
}
.inputForget:hover{
    border: 1px solid rgba(79,187,205,1);
}
.inputForget:focus{
    border: 1px solid rgba(79,187,205,1);
}
.btnForget{
    width: 100%;
    height: 35px;
    font-size: 16px;
    margin-top: 10px;
}

.textGoLogin, .goLogin,.aviso{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #7A959B;
    display: inline;
}
.goLogin, .aviso{
    cursor: pointer;
}
