.modal_result_pay .ant-modal-content{
    padding: 20px 35px;
}
.result_pay{
    font-family: 'Lato', sans-serif;
}
.ant-result-success .ant-result-icon>.anticon{
    color: #44ADAB;
}
.ant-result-error .ant-result-icon>.anticon{
    color: #44ADAB;
}

@media (max-width: 395px) {
    .btn_go_home{
        margin-left: -8px;
    }
}