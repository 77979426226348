
.titleRegister{
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #44ADAB;
}
.formRegister{
    width: 100%;
    margin-top: 20px;
    padding: 0% 8%;
}
.inputFormRegister{
    font-family: 'Lato', sans-serif;
    width: 100%;
    color: rgb(129, 129, 129);
    border: 1px solid rgb(163, 163, 163);
}
.inputFormRegister:hover{
    border: 1px solid rgba(79,187,205,1);
}
.inputFormRegister:focus{
    border: 1px solid rgba(79,187,205,1);
}
.btnRegister{
    width: 100%;
    height: 35px;
    font-size: 16px;
    margin-top: 30px;
}