.ModalChat .ant-modal-content{
    padding: 0px 0px 0px 8px;
}

.inputChat{
    border: 1px solid #a2a3a3;
}
.inputChat:hover, .inputChat:focus{
    border: 1px solid #44adab;
}

.divClose {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rce-navbar-item__right{
    position: absolute;
    right: 10px !important;
}