.formCodeVerification{
    width: 100%;
    margin-top: 20px;
    padding: 0% 8%;
}
.instructionCode{
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #7A959B;
}
.colTextCode{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.colResend{
    display: flex;
    justify-content: flex-end;
}
.textCode{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #747474;
}
.resend{
    color: #747474;
    padding-right: 5px;
}
.resend:not(:disabled):not(.ant-btn-disabled):hover, .resend:not(:disabled):not(.ant-btn-disabled):active{
    color: #44ADAB;
    font-weight: 700;
}
.colInputsTwo{
    display: flex;
    justify-content: center;
    padding: 0% 2%;
}
.inputsCode{
    width: 40px;
    height: 40px;
    text-align: 'center';
    margin-right: 8px;
    margin-top: 10px;
    background-color: transparent;
    border: 2px solid rgb(163, 163, 163);
    color: #747474;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    text-align: center;
}
.inputsCode:hover, .inputsCode:focus{
    border: 1px solid rgba(79,187,205,1);
}
.btnValidar{
    width: 100%;
    height: 35px;
    font-size: 16px;
    margin-top: 30px;
}
.btnBack{
    width: 100%;
    height: 35px;
    font-size: 16px;
    margin-top: 10px;
    background-color: transparent;
    border:1px solid #44ADAB;
    color: #44ADAB;
}
.emailInst{
    font-family: 'Lato', sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #516569;
}
@media (max-width: 480px) {
    .inputsCode{
        width: 30px;
        height: 30px;
    }
}

