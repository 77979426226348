.ticketCard{
    background-color: white;
    border-radius: 5px;
    padding: 3% 5%;
    box-shadow: 0px 3px 8px 2px #44adab93;
    margin-bottom: 12px;
}
.colClave, .colPaso{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 8px;
}
.claveTicket, .statusTicket, .textPaso{
    display: inline-flex;
    font-family: 'Lato', sans-serif;
    font-weight: 600;

}
.claveTicket{
    color: #44adab;
    font-size: 18px;
}
.statusTicket{
    color: #7e8383;
    margin-left: 10px;
    font-size: 14px;
}

.colImg{
    display: flex;
    justify-content: center;
    align-items: center; 
    padding-top: 5px;
}

.colAsignacion{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

}
.asignacionTicket{
    display: inline-flex;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: #7e8383de;
    font-size: 12px;
}
.textPaso{
    color: #7e8383;
    font-size: 14px;  
}

.colBtnSeguimiento{
    display: flex;
    justify-content: flex-start;
}
.colBtnClose{
    display: flex;
    justify-content: flex-end;
}
.colBtnComunicacion{
    display: flex;
    justify-content: flex-end;
}

.btnSeguimiento, .btnComunicacion{
    width: 50%;
    height: 35px;
    font-size: 14px;
    font-weight: 600;
}

.btnChat{
    display: flex;
    justify-content: center;
    align-items: center;
}
 .iconChat:hover{
    color: white;
}

.btnChat:hover{
    color: white !important;
    border: none !important;
}
.iconChat:focus{
    color: white;
}

@media (max-width: 480px) {
    .btnSeguimiento, .btnComunicacion{
        font-size: 10px;
    }
    .statusTicket{
        font-size: 12px;
    }
    .claveTicket{
        font-size: 16px;
    }
    .textPaso{
        font-size: 12px;  
    }
}