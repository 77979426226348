.cardTabs{
    background-color: white;
    border-radius: 10px;
    padding: 0% 0%;
    box-shadow: 0px 3px 8px 2px #44adab93;
}

.titleTab{
    font-family: 'Lato', sans-serif;
    color: #7e8383;
    font-size: 14px;
    font-weight: 600;
    padding-left: 20px;
}
.ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{
    margin-left: 10px;
}

@media (max-width: 480px) {
    .titleTab{
        font-size: 12px;
        padding-left: 8px;
    }
    .ant-tabs-top >.ant-tabs-nav .ant-tabs-ink-bar{
        margin-left: 5px;
    }
}