.cardConversation{
    background-color: white;
    border-radius: 5px;
    padding: 2% 3%;
    box-shadow: 0px 3px 8px 2px #44adab93;
}
.titlesMsg{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    display: inline;
}
.cardMessages{
    border: 1px solid #44adab93;
    border-radius: 5px;
    padding: 3%;
    margin-top: 20px;
}
.anotationTitle{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 600;
    margin-top: 5px;
}
.messageData{
    color: #666868;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
}
.messageSelect{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
}
.colBtnSend, .colInput, .colIconFiles{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #44adab;
    margin-top: 15px;
    border-radius: 8px;
    height: 40px;
}
.colBtnSend{
    border-left: none;
    border-right: none;
    border-radius: 0;
}
.colInput{
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.colIconFiles{
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 100% !important;
}
.inputMessage{
    border: none;
}
.ant-upload-wrapper .ant-upload-select{
    display: block;
}