.cardSeguimientoData{
    background-color: white;
    border-radius: 5px;
    padding: 2% 3%;
    box-shadow: 0px 3px 4px 1px #44adab93;
    /* border-top: none; */
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -2.5px;
    padding-bottom: 5%;
}
.colTitleSeguimiento{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.titleCardSeguimiento{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-size: 22px;
    font-weight: 700;
}
.folioSeguimiento{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 700;
    margin-top: 5px;
}

.colRegisterCount{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.textRegisterCount{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    display: inline;
}
.registerCount{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-top: 5px;
    display: inline;
    margin-left: 5px;
}

.tableSeguimiento .ant-table-tbody >tr >td{
    border: none;
    text-align: center;
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 10px;
    cursor: default;
}
.iconNumber{
    background-color: #44adab;
    vertical-align: middle;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: white;
    font-weight: 600;
}
.titleSeguimiento{
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    color: #7e8383;
    font-weight: 600;
}
.fechaSeguimiento{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 12px; 
    padding-right: 12px;
}

.btnAdjuntos{
    border-radius: 50%;
    height: 100%;
    padding: 10px;
}