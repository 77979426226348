.nameFiles{
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    color: #7e8383;
    font-weight: 600;
    cursor: pointer;
}
.nameFiles:hover{
    color: #368f8d;
    font-size: 13px;
}
.nofiles{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    color: #7e8383;
    font-weight: 700;
}