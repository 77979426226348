.divTabPersonal{
    background-color: white;
    padding: 2% 2%;
    box-shadow: 0px 3px 8px 2px #44adab93;
}
.colInputSm{
    padding: 0px 20px;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    text-align: start;
    
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    text-align: start;
}
.inputTabPersonal{
    font-family: 'Lato', sans-serif;
    width: 100%;
    color: rgb(129, 129, 129);
    border: 1px solid rgb(163, 163, 163);
}
.inputTabPersonal:hover{
    border: 1px solid rgba(79,187,205,1);
}
.inputTabPersonal:focus{
    border: 1px solid rgba(79,187,205,1);
}
.formTabPersonal .ant-form-item-label>label{
    font-family: 'Lato', sans-serif;
    color: rgb(129, 129, 129)
}