.headerLayout{
    height: 65px;
    background: linear-gradient(0deg, rgba(1,150,198,1) 19%, rgba(52,168,205,1) 98%);
    width: 100%;
    padding-inline: 10px;
}
.rowLogo{
    height: 100%;
}
.colLogo, .colNombre{
    display: flex;
    justify-content: center;
    align-items: center;
}
.colNombre{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.rowMoney{
    height: 100%;
    display: flex;
    justify-content: flex-end;
}
.colHeadersMoney{
    display: flex;
    align-items: center;
}
.ColIconMoney{
    justify-content: flex-end;
    margin-right: 5px;
}
.moneyText{
    font-family: 'Lato', sans-serif;
    color: white;
    font-weight: 700;
}
.ant-dropdown .ant-dropdown-menu{
    width: 280px;
    padding-bottom: 20px;
}
.nameUser{
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #7A959B;
    line-height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.listaMenuItem{
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 600;
    color: #7A959B;
    margin-left: 10px;
}
.linkTickets:hover > .ant-row > .listaMenuItem {
    color: #44ADAB;
    font-size: 18px;
}
.listaMenuItem:hover{
    color: #44ADAB;
    font-size: 18px;
}
@media (max-width: 480px) {
    .imgLogo{
        height: 35px;
    }
    .imgNombre{
        height: 15px;
    }
    .colNombre{
        display: none
    }
}
@media (min-width:481px) and (max-width: 768px) {
    .imgLogo{
        height: 45px;
    }
    .imgNombre{
        height: 25px;
    }
    .colNombre{
        display: none
    }
}
@media (min-width:769px) and (max-width: 1024px) {
    .imgLogo{
        height: 50px;
    }
    .imgNombre{
        height: 30px;
    }
}
@media (min-width: 1025px) {
    .imgLogo{
        height: 45px;
    }
    .imgNombre{
        height: 35px;
    }
}


.iconMoney {
    position: relative;
    color: #262626;
}
.iconMoneyContainer{
    display: flex;
    justify-content: center;
    height: 100%;
    align-items: center;
    height: 30px;
    border-radius: 50%;
    background-color:#FFFAFA ;
    width: 30px;
}
.rowMoney:hover .iconMoneyContainer{
    background: linear-gradient(180deg, rgba(209,173,0,1) 20%, rgba(237,221,8,1) 100%);
}
.rowMoney:hover .iconMoney {
    animation: money .5s;
    background-color: transparent;
    color: #FFFAFA;
}
@keyframes money {
    0% {
        transform: rotateY(0deg);
        color: #212933;
    }
    25% {
        transform: rotateY(90deg);
        color: #212933;
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover{
    background-color: transparent;
}
.titleModalSesion{
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: #424141;
    font-size: 18px
}
.btnAceptarModal{
    height: 35px;
    font-size: 16px;
}
.btnAceptarModal:hover{
    color: #FFFAFA;
    scale: 1.1;
}
.btnAceptarModal:active{
    color: #FFFAFA;
    scale: 0.9;
}
.btnAceptarModal:not(:disabled):not(.ant-btn-disabled):hover, .btnAceptarModal:not(:disabled):not(.ant-btn-disabled):active{
    color: #FFFAFA;
    border: none;
}
.btnCancelSsn{
    color: rgb(77, 73, 73);
    height: 35px;
    font-size: 16px;
    margin-right: 10px;
}
.btnCancelSsn:not(:disabled):not(.ant-btn-disabled):hover{
    color: rgb(77, 73, 73);
    scale: 1.1;
}
.btnCancelSsn:not(:disabled):not(.ant-btn-disabled):active{
    color: rgb(77, 73, 73);
    scale: 0.9;
}

.textHelp{
    font-family: 'Lato', sans-serif;
    color: white;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
}
.iconHelp{
    color: white;
    cursor: pointer;
}
.ant-layout-header{
    line-height: 17px !important;
}