.time{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
    display: inline;
}
.closeLabel{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-top: 5px;
    display: inline;
}
.caducado{
    color: #e93b1c;
}
