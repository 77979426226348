.container {
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    overflow: hidden;
    border-radius: 5px;
    padding: 1px 5px;
    margin-bottom: 10px;
}  

.triangle {
    position: absolute;
    top: 3;
    right: 0px; /* Ajusta este valor según tus necesidades */
    width: 10x;
    height: 3px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 15px solid #ffffff; /* Mismo color del fondo */
    z-index: 1;
    background-color: transparent;
}
.divBadage{
    z-index: 0;
    color: #ffffff;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Lato', sans-serif;
}
.containerAtention{
    background-color: #44adab;
    border: 3px  solid #44adab;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 30%;
}
.trianguleAtention{
    rotate: 180deg;
}
.containerNexts{
    background-color: rgb(211, 8, 8);
    border: 3px  solid rgb(211, 8, 8);
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 30%;
}
.badageNexts{
    text-align: end;
    position: absolute;
    right: 5px;
}
.trianguleNexts{
    left: 0px;
}
.noList{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    text-align: start;
    font-size: 16px;
}

.colTicketWait{
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
    margin-bottom: 5px;
}
.colTicketNext{
    display: flex;
    justify-content: flex-end;
    padding-left: 10px;
    margin-bottom: 5px;
}
.ticketLabel{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    text-align: start;
    font-size: 16px;
}