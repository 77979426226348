.cardAsesor{
    background-color: white;
    border-radius: 5px;
    padding: 2% 3%;
    /* box-shadow: 0px 3px 4px 1px #44adab93; */
    border-top: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -2.5px;
    padding-bottom: 5%;
}
.nameAsesor{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    padding-bottom: 3px;
}
.tagNoAptitud{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    padding-bottom: 3px;
}
.titleAsesor{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
}
.ant-tag{
    background-color: transparent !important;
    font-size: 10px !important;
}

.tickets{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
}
.titleCompetencia{
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}
.colCarrousel{
    padding: 0% 6%;
}
.cardOpinion{
    border-radius: 8px;
    padding: 2% 3%;
    background-color: #44adabb2;
}
.carous{
    padding: 0% 2%;
}
.nameOpinion{
    font-family: 'Lato', sans-serif;
    color: white;
    font-size: 12px;
    font-weight: 600;
}
.companyOpinion{
    font-family: 'Lato', sans-serif;
    font-size: 10px;
    line-height: 2px;
    margin-top: -5px;
    color: white;
}

@media (max-width: 480px){
    .titleCompetencia{
        font-size: 10px;
    }

    .titleAsesor{
        font-size: 10px;
    }
    .nameAsesor{
        font-size: 12px;
    }
    .nameOpinion{
        font-size: 10px;
    }
    .companyOpinion{
        font-size: 8px;
    }
    .colCarrousel{
        padding: 0% 2%;
    }
}