.cardLogin{
    background-color: white;
    border-radius: 12px;
    padding: 20px 0px;
    box-shadow: 2px 6px 8px 2px rgba(0,0,0,0.33);
}
.titleLogin{
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #44ADAB;
}
.inputCredential, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled){
    font-family: 'Lato', sans-serif;
    width: 100%;
    color: rgb(129, 129, 129);
    border: 1px solid rgb(163, 163, 163);
}
.inputCredential:hover, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
    border: 1px solid rgba(79,187,205,1);
}
.inputCredential:focus, .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):focus{
    border: 1px solid rgba(79,187,205,1);
}
.colForgetPass{
    display: flex;
    justify-content: right;
    margin-top: -15px;
}
.btnIngresar{
    width: 100%;
    height: 35px;
    font-size: 16px;
    margin-top: 30px;
}
.colGoRegister{
    display: flex;
    align-items: center;
    justify-content: center;
}
.textGoRegiter{
    margin-right: 5px;
}
.textGoRegiter, .goRegister, .avisoPrivacidad{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #7A959B;
}
.goRegister, .avisoPrivacidad{
    cursor: pointer;
}
.goRegister:hover, .avisoPrivacidad:hover{
    color: #44ADAB;
    font-weight: 600;
}