.rowTabPsswrd{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.colTextPsswrd{
    padding: 2%;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.textTabPsswrd{
    font-family: 'Lato', sans-serif;
    color: #7e8383;
    font-size: 20px;
    font-weight: 600;
}
.colBtnPsswrd{
    padding: 2%;
    margin-top: 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.btnChangePsswrd{
    height: 35px;
    width: 30%;
    font-size: 16px;
}
@media (max-width: 480px) {
    .btnChangePsswrd{
        width: 50%;
        font-size: 14px;
    }
}
@media (max-width: 720px) {
    .btnChangePsswrd{
        width: 40%;
        font-size: 14px;
    }
    .textTabPsswrd{
        font-size: 15px;
    }
}