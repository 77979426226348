.preguntaTitulo{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: #565757;
    font-size: 14px;
}
.radioBtn{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: #565757;
    font-size: 14px;
}
.radioBtn .ant-radio-checked .ant-radio-inner{
    background-color: #3ba3a1;
    border-color: #3ba3a1;
}

.checkBtn{
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    color: #565757;
    font-size: 14px;
}