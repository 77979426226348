.cardTableTicket{
    background-color: white;
    border-radius: 5px;
    padding: 2% 3%;
    box-shadow: 0px 3px 8px 2px #44adab93;
}

.colTitleTableTickets{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.titleTableTickets{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    font-weight: 700;
}
.colSearch{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tableData .ant-table{
    border-radius: 10px;
    box-shadow: 0px 3px 8px 2px #44adab8c;
}
.ant-table-wrapper .ant-table-thead >tr>th{
    border: none;
    text-align: center;
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
}
.ant-table-wrapper .ant-table-tbody >tr >td{
    border: none;
    text-align: center;
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
}
.tableData .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent !important;
}
.ant-pagination-item-link{
    color: #44adab !important;
}
.ant-table-wrapper td.ant-table-column-sort{
    background: transparent;
}
.ant-pagination .ant-pagination-item-active, .ant-pagination .ant-pagination-item-active:hover, .ant-pagination .ant-pagination-item-active:active{
    color: #44adab !important;
    border: 1px solid #44adab;
}
.ant-pagination .ant-pagination-item-active:hover{
    scale: 1.1;
}
.ant-pagination .ant-pagination-item-active a{
    color: #44adab !important;
}
.ant-pagination .ant-pagination-item a {
    color: #7e8383;
}
.ant-table-wrapper .ant-table-pagination.ant-pagination{
    margin: 0px;
    margin-top: 15px;
}