.cardImgProfile{
    background-color: white;
    border-radius: 10px;
    padding: 0% 0%;
    box-shadow: 0px 3px 8px 2px #44adab93;
}
.imgBack{
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}


.nameCardUser{
    font-family: 'Lato', sans-serif;
    color: #7e8383;
    font-size: 18px;
    font-weight: 600;
}