.colCardsTickets{
    padding: 2%;
}

.cardCreateTicket{
    background-color: white;
    border-radius: 5px;
    padding: 3% 5%;
    box-shadow: 0px 3px 8px 2px #44adab93;
}

.titleTour{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    text-align: start;
    font-size: 16px;
}
.textTour{
    color: #727474;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    text-align: justify;
    font-size: 14px;
}
.ant-tour-prev-btn{
    font-family: 'Lato', sans-serif;
    background-color: white;
    color: #44adab;
}
.ant-tour-next-btn{
    font-family: 'Lato', sans-serif;
    background-color: #44adab;
    color: white;
}
.ant-tour-prev-btn:hover{
    font-family: 'Lato', sans-serif;
    background-color: white !important;
    color: #44adab !important;
    border: 1px solid #44adab !important; 
    scale: 1.1;
}
.ant-tour-next-btn:hover{
    font-family: 'Lato', sans-serif;
    background-color: #44adab !important;
    color: white;
    scale: 1.1  ;
}
.ant-tour .ant-tour-inner .ant-tour-footer .ant-tour-indicators .ant-tour-indicator-active {
    color: #44adab;
    background: #44adab;
}

.ant-checkbox-checked .ant-checkbox-inner{
    background: #44adab;
    border-color: #44adab;
}
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner{
    background: #44adab;
    border-color: #44adab;
}