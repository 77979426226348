.btn_pdf{
    color: white;
    background-color: rgb(177, 5, 5);
    font-weight: 600;
    border: none;
}
.btn_xml{
    color: white;
    background-color: #3ca4d8;
    font-weight: 600;
    border: none;
}