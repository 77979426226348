.ant-modal .ant-modal-content{
    background-color: white;
    font-family: 'Lato', sans-serif;
    padding: 20px 60px;
}
.ant-modal .ant-modal-title{
    color: #424141 !important;
    font-weight: 600;
    font-size: 18px;
}
.ant-modal .ant-modal-header{
    background-color: white;
    font-family: 'Lato', sans-serif;
    text-align: center;
    color: #424141 !important;
    margin-bottom: 30px;
}
.instruction{
    color: #424141;
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    display: flex;
    align-content: center;
    align-items: center;
}
.rowInstructionPss{
    text-align: start;
}
.inputPsw{
    color: rgb(73, 71, 71);
    border: 1px solid rgb(163, 163, 163);
}
.btnAceptar{
    border-radius: 8px;
    background: linear-gradient(180deg, #44ADAB 28.65%, #4DA6DE 100%);
    width: 100%;
    height: 40px;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 600;
    border: none;
}

.circleFill{
    color: rgba(7, 187, 7, 0.589);
    margin-left: 10px;
}

.titleModalPss{
    color: #424141;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600;
    margin-top: -10px;
}
.closeModalPass{
    color: #030303;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    height: min-content;
    margin-top: -10px;
    margin-right: -20px;
    cursor: pointer;
}