.cardRegister{
    background-color: white;
    border-radius: 12px;
    padding: 20px 0px;
    box-shadow: 2px 6px 8px 2px rgba(0,0,0,0.33);
}
.textGoLogin, .goLogin,.aviso{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #7A959B;
    display: inline;
}
.tC, .goLogin, .aviso{
    cursor: pointer;
}
.tC:hover, .goLogin:hover,.aviso:hover{
    color: #44ADAB;
    font-weight: 600;
}

.textTC, .tC{
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 500;
    color: #151a1b;
    display: inline;
    font-weight: bolder;
}
