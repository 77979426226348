.messageText{
    color: #424141;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    display: flex;
    align-content: center;
    align-items: center;
    text-align: justify;
}
.titleModalMss{
    color: #3ba3a1;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 700;
    margin-top: -10px;
}