.footerLayout{
    /* height: 74px; */
    /* margin-top: 5px; */
    background-color: #485050;
    padding: 0% 1%;
}
.rowOneFooter{
    padding: 10px 0;
}
.dataSeller{
    color: white;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
}
.titleSeller{
    color: rgb(192, 190, 190);
    font-family: 'Lato', sans-serif;
    font-weight: 500;
}

.columnTitleSeller{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-left: 1px solid white;
    height: 90%;
    margin-top: 8px;
}
.columnDataSeller{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

@media (max-width: 520px) {
    .footerLayout{
        height: 90px;
        margin-top: 125px;
        padding: 0% 1%;
    }

    .dataSeller{
        font-size: 8px;
    }
    .titleSeller{
        font-size: 8px;
        margin-left: 6px;
    }
    .columnDataSeller{
        height: 100%;
        padding-left: 10px;
        justify-content: center;
    }
    .columnTitleSeller{
        height: 100%;
        margin-top: -5px;
    }
    .rowOneFooter{
        height: 100%;
        display: flex;
        align-items: center;
    }
}