
.createTicket-card{
    background-color: white;
    border-radius: 5px;
    padding: 2% 5%;
    box-shadow: 0px 3px 8px 2px #44adab93;
}

.titleCreateTicket{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    text-align: start;
    font-size: 20px;
}
.ant-select-selector{
    border: 1px solid rgb(197, 195, 195) !important;
}
.ant-select-selector:hover,.ant-select-selector:focus{
    border: 1px solid rgba(79,187,205,1) !important;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder{
    color: rgb(180, 178, 178);
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item{
    text-align: start;
    color: rgb(201, 198, 198);
}

.ant-upload-wrapper .ant-upload-drag p.ant-upload-hint, .ant-upload-wrapper .ant-upload-drag p.ant-upload-text{
    font-family: 'Lato', sans-serif;
    font-size: 10px;
}
.ant-upload-wrapper .ant-upload-drag p.ant-upload-drag-icon .anticon{
    font-size: 25px;
}
.ant-upload-wrapper .ant-upload-drag .ant-upload-btn {
    padding: 0 !important;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-name,
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-icon .anticon,
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item .ant-upload-list-item-actions .anticon{
    color: rgb(3, 3, 3);
    font-family: 'Lato', sans-serif;
    font-size: 12px;
}
.ant-upload-wrapper .ant-upload-list .ant-upload-list-item {
    text-align: start;
}
.btnSendTicket{
    width: 100%;
    height: 35px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 8px;
}