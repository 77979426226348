.name_company{
    color: #686868;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}
.data_company{
    color: #888787;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
}
.title_buy{
    color: #636262;
    font-family: 'Lato', sans-serif;
    font-size: 16px;
}
.table_invoice .ant-table{
    border-radius: 10px;
}

.table_invoice .ant-table-thead >tr>th{
    border: none;
    text-align: center;
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 14px;
    background-color: transparent;
    border-bottom: 1px solid #7e8383;
    padding: 5px;
}
.table_invoice .ant-table-tbody >tr >td{
    border: none;
    text-align: center;
    color: #7e8383;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    font-size: 10px;
    cursor: pointer;
    padding: 8px;
}
.table_invoice .ant-table-tbody > tr.ant-table-row:hover > td {
    background-color: transparent !important;
}

.table_invoice .ant-table-thead >tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    background-color: transparent;
}

.sub_total{
    color: #9e9e9e;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
}
.title_total{
    color: #636363;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
}
.price_total{
    color: #44adab;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 600;
}